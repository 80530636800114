import { createApp } from "vue";
import App from "./App.vue";
import AppClr from "./AppClr.vue";
import axios from "axios";
import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import CmsPage from "./views/CmsPage.vue";
import TopView from "./components/TopView.vue";
import BottomView from "./components/BottomView.vue";
import LeftView from "./components/LeftView.vue";
import RightView from "./components/RightView.vue";
import { createI18n } from 'vue-i18n'
import jQuery from 'jquery';

axios.defaults.withCredentials = true;
export const api = axios.create({
  baseURL:  window.API_URL || "/api/",
});
window.$ = window.jQuery = jQuery;
const getWebConfig = () => {
  return api
    .get(window.WEB_TEMPLATE? window.WEB_TEMPLATE : (window.THEME? "cms/web?theme=" + window.THEME :  "cms/web") )
    .then((response) => response.data)
    .catch((error) => {
      // Handle errors here, e.g., log or throw an error
      console.error("Error fetching web config:", error);
      throw error;
    });
};

async function addRoute(a) {
  if (Array.isArray(a)) {
    a.forEach((b) => {
      addRoute(b);
    });
  } else {
    a.components = { default: CmsPage, topview: TopView, bottomview: BottomView, leftview: LeftView, rightview: RightView };
  }
}

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'zh-TW': {
    currency: {
      style: 'currency', currency: 'NTD', notation: 'standard', minimumFractionDigits: 0, maximumFractionDigits: 0
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'ja-JP': {
    currency: {
      style: 'currency', currency: 'JPY', useGrouping: true, currencyDisplay: 'symbol'
    },
    decimal: {
      style: 'decimal', minimumSignificantDigits: 3, maximumSignificantDigits: 5
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  }
}

Date.prototype.format = function (format) {
  /*
   * eg:format="yyyy-MM-dd hh:mm:ss";
   */
  if (!format) {
    format = "yyyy-MM-dd hh:mm:ss";
  }

  var o = {
    "M+": this.getMonth() + 1, // month
    "d+": this.getDate(), // day
    "h+": this.getHours(), // hour
    "m+": this.getMinutes(), // minute
    "s+": this.getSeconds(), // second
    "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
    "S+": this.getMilliseconds(),
    // millisecond
  };

  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return format;
};

getWebConfig().then((res) => {
  localStorage.setItem("res", JSON.stringify(res));
  addRoute(res.route);
  if(window.DEBUG)console.log(res.route);
  let router = createRouter({
    history: window.WebHash? createWebHashHistory() : createWebHistory(),
    routes: res.route,
  });

  const i18n = createI18n({
    numberFormats
  })

  const app =  window.AppClr? createApp(AppClr) : createApp(App);
  app.config.globalProperties.$globalVariable = {ticket:0, res: res};
  app.use(router).use(i18n);

  if(window.DEBUG)console.log(router.version);
  router.isReady().then(() => {
    // router.replace('/');  // 在路由準備好之後立即導航
    if(window.DEBUG) console.log(window.location.pathname);
    var launch = document.getElementById('launch');
    if(launch) launch.style.display='none';
  });

  app.mount("#app");
});
