<template >
 <div>
  <div id="preview" v-if="preview == 'Y'">
   <span>預覽模式</span>
   <a id="closepreview" @click="closePreview()">
    <i class="fa fa-window-close"></i>
   </a>
  </div>
  <Header ref="Header" />
  <Top ref="Top" />
  <div id="main-container">
   <div id="left"><Left ref="Left" /></div>
   <div id="center"><router-view /></div>
   <div id="right"><Right ref="Right" /></div>
  </div>
  <Bottom ref="Bottom" />
  <Footer ref="Footer" />
 </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import Header from "./components/HeaderTemplate.vue";
import Footer from "./components/FooterTemplate.vue";
import Top from "./components/TopView.vue";
import Bottom from "./components/BottomView.vue";
import Left from "./components/LeftView.vue";
import Right from "./components/RightView.vue";
export default {
 name: "App",
 props: ["res"],
 components: {
  Header,
  Footer,
  Top,
  Bottom,
  Left,
  Right,
 },
  provide() {
    return {
      sharedFunction: this.sharedFunction
    };
  },
 data() {
  return {
   route: useRoute(),
   router: useRouter()
  };
 },
 created: function () {
  var res = JSON.parse(localStorage.getItem("res"));
  if (res.script_links) {
   res.script_links.split("\n").forEach((element) => {
    var aScript = document.createElement("script");
    aScript.setAttribute("src", element);
    document.head.appendChild(aScript);
   });
  }
  if (res.css_links) {
   res.css_links.split("\n").forEach((element) => {
    var linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("href", element);
    document.head.appendChild(linkElement);
   });
  }
  const styleTag = document.createElement("style");
  styleTag.type = "text/css";
  const cssStyles = res.css;
  styleTag.textContent = cssStyles;
  document.head.appendChild(styleTag);

// 查找或創建一個動態的 <style> 標籤
let dynamicStyleTag  = document.createElement("style");
dynamicStyleTag.id = "dynamic-style";
document.head.appendChild(dynamicStyleTag);


  const meta = document.createElement("meta");
  meta.name = "viewport";
  meta.content =
   "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
  document.getElementsByTagName("head")[0].appendChild(meta);
 },
 mounted: function () {
  if(window.DEBUG)console.log("App.vue mounted");
 },
};
</script> 