<template>
 <v-runtime-template :template="template"></v-runtime-template>
</template>
<script>
import VRuntimeTemplate from "vue3-runtime-template";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
axios.defaults.withCredentials = true;
const api = axios.create({
 baseURL: window.API_URL || "/api/",
});
export default {
 name: "HeaderTemplate",
 data() {
  var res = JSON.parse(localStorage.getItem("res"));
  return {
   v: this,
   w: window,
   h: axios,
   template: res.templates.headerTemplate.content,
   res: { ...res },
   route: useRoute(),
   router: useRouter(),
   apiEndpoint: null
  };
 },
 components: {
  VRuntimeTemplate,
 },
 watch: {
  // Watch for route changes
  $route() {
   if (
    this.$globalVariable.header_ws &&
    this.$globalVariable.header_ws !== null
   )
    this.websocketsend("view=" + this.$route.name);
   if (window.DEBUG) console.log("header_route_reload");
   this.jQuery("#header_route_reload").click();
   if (this.res.header_route_reload) this.res.header_route_reload(this);
  },
 },
 methods: {
  jQuery(selectort) {
   return $(selectort);
  },
  sumOfList(list, prop) {
   var s = 0;
   if (list && Array.isArray(list)) {
    list.forEach((element) => {
     s += parseFloat(element[prop]);
    });
   }
   return s;
  },
  sumOfObj(dyn, call) {
   if (!dyn) return 0;
   var s = 0;
   for (var i in dyn) {
    s += parseFloat(call(dyn[i]));
   }
   return s;
  },
  swalFire(obj, _then) {
   var v = this;
   if (obj.preConfirmFormID) {
    obj.preConfirm = this.preCheckForm(obj.preConfirmFormID);
   }
   Swal.fire(obj).then((result) => {
    if (obj.isConfirmed && result.isConfirmed) obj.isConfirmed(result);
    if (_then) _then(result, v, window);
   });
  },
  refresh() {
   this.$router.go(this.$router.currentRoute);
  },
  saveStorage(a, b) {
   this.$globalVariable[a] = b;
   localStorage.setItem(a, JSON.stringify(b));
  },
  getStorage(a) {
   if (!localStorage.getItem(a)) return this.$globalVariable[a];
   var b = localStorage.getItem(a);
   if (b) b = JSON.parse(b);
   return b;
  },
  fetch(actionUrl, succ, err) {
   var v = this;
   axios
    .get(actionUrl)
    .then((response) => {
     if (window.DEBUG) console.log(response);
     if (response.data && response.data.error && err) {
      err(response.data.error, v, window);
     } else if (succ) {
      succ(response.data, v, window);
     }
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
     if (err) {
      err(error, v, window);
     }
    });
  },
  request_stringify(data) {
   return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
  },
  post(actionUrl, data, succ, err) {
   var v = this;
   axios.defaults.withCredentials = true;
   axios
    .post(actionUrl, data, {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
     if (window.DEBUG) console.log(response);
     if (response.data && response.data.error && err) {
      err(response.data.error, v, window);
     } else if (succ) {
      succ(response.data, v, window);
     }
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
     if (err) {
      err(error, v, window);
     }
    });
  },
  preCheckForm(formID) {
   return new Promise((resolve, reject) => {
    setTimeout(() => {
     const inputs = document.querySelectorAll(
      "#" + formID + " input,#" + formID + " textarea,#" + formID + " select"
     );
     for (let input of inputs) {
      const pattern = input.getAttribute("pattern");
      if (pattern && !new RegExp(pattern).test(input.value)) {
       reject("驗證失败");
       return;
      }
     }
     let result = {};
     inputs.forEach((input) => {
      result[input.name] = input.value;
     });
     resolve(result);
    }, 200);
   });
  },
  submitFormJson(formID, actionUrl, succ_call, error_call) {
   var formData = {};
   this.jQuery("#" + formID)
    .find(":input[name]")
    .each(function () {
     formData[this.name] = this.jQuery(this).val();
    });
   axios
    .post(actionUrl, "json=" + JSON.stringify(formData), {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
     if (window.DEBUG) console.log(response);
     if (succ_call) succ_call();
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
     if (error_call) error_call();
    });
  },
  getCmsList() {
   // 檢查 apiEndpoint 是以 '/' 還是以 '{' 開頭
   if (
    this.apiEndpoint.startsWith("ws://") ||
    this.apiEndpoint.startsWith("wss://")
   ) {
    return Promise.resolve();
   }

   if (
    this.apiEndpoint.startsWith("http://") ||
    this.apiEndpoint.startsWith("https://")
   ) {
    axios.defaults.withCredentials = true;
    // 使用 axios 直接请求，而不使用通过 axios.create 创建的实例
    return axios
     .get(this.apiEndpoint)
     .then((response) => {
      return response.data;
     })
     .catch((error) => {
      console.error("请求外部 API 时出错:", error);
      return Promise.reject(error);
     });
   } else if (this.apiEndpoint.startsWith("/")) {
    // 原始方式處理
    return api
     .get(this.apiEndpoint)
     .then((response) => response.data)
     .catch((error) => {
      console.error("Error fetching web config:", error);
      return Promise.reject(error);
     });
   } else if (this.apiEndpoint.startsWith("{")) {
    // 將 apiEndpoint 解析為 JSON 對象
    try {
     const endpoints = JSON.parse(this.apiEndpoint);
     const promises = Object.entries(endpoints).map(([key, endpoint]) => {
      if (endpoint.startsWith("ws://") || endpoint.startsWith("wss://")) {
       return Promise.resolve(); // 直接返回一个解决的Promise
      }
      if (endpoint.startsWith("http://") || endpoint.startsWith("https://")) {
       // 使用 axios 直接请求，而不使用通过 axios.create 创建的实例
       axios.defaults.withCredentials = true;
       return axios
        .get(endpoint)
        .then((response) => {
         return { [key]: response.data };
        })
        .catch((error) => {
         console.error("请求外部 API 时出错:", error);
         return Promise.reject(error);
        });
      }
      return api.get(endpoint).then((response) => ({ [key]: response.data }));
     });

     // 等待所有請求完成並合併結果
     return Promise.all(promises)
      .then((results) => Object.assign({}, ...results))
      .catch((error) => {
       console.error("Error fetching multiple web configs:", error);
       throw error;
      });
    } catch (error) {
     console.error("Error parsing JSON apiEndpoint:", error);
     throw error;
    }
   }
  },
  initWebsocket(wsURL) {
   if (
    this.$globalVariable.header_ws &&
    this.$globalVariable.header_ws !== null
   )
    return;
   if (!wsURL && this.$globalVariable.header_wsURL)
    wsURL = this.$globalVariable.header_wsURL;
   if (!wsURL) return;
   this.$globalVariable.header_wsURL = wsURL;
   this.$globalVariable.header_ws = new WebSocket(wsURL);
   this.$globalVariable.header_ws.onopen = this.websocketonopen;
   this.$globalVariable.header_ws.onerror = this.websocketonerror;
   this.$globalVariable.header_ws.onmessage = this.websocketonmessage;
   this.$globalVariable.header_ws.onclose = this.websocketclose;
   this.$globalVariable.header_ws_route = this.$route.name;
  },

  waitForConnection(callback, interval) {
   if (
    this.$globalVariable.header_ws &&
    this.$globalVariable.header_ws.readyState === 1
   ) {
    callback();
   } else {
    setTimeout(() => {
     this.waitForConnection(callback, interval);
    }, interval);
   }
  },

  websocketonopen() {
   this.websocketsend("view=" + this.$route.name);
   this.$globalVariable.header_wsTimer = setInterval(() => {
    this.websocketsend("1");
   }, 3000);
  },

  websocketonerror() {
   clearInterval(this.wsTimer);
   this.$globalVariable.header_wsTimer = null;
   this.$globalVariable.header_ws = null;
   setTimeout(this.initWebsocket, 1000);
  },

  websocketonmessage(e) {
   const data = e.data;
   if (data === "OK" || data === "load" || data === "2") return;

   try {
    const jsonData = JSON.parse(data);
    if (!this.$globalVariable.header_ws_response)
     this.$globalVariable.header_ws_response = [];
    this.$globalVariable.header_ws_response.push(jsonData);
    this.jQuery("#header_websocketonmessage").click();
    if (this.res.header_websocketonmessage)
     this.res.header_websocketonmessage(this);
   } catch (error) {
    console.error("Error parsing JSON:", error);
   }
  },

  websocketsend(data) {
   if (this.$globalVariable.header_ws && this.$globalVariable.header_ws.readyState === WebSocket.OPEN) {
    this.$globalVariable.header_ws.send(data);
   }
  },

  websocketclose() {
   clearInterval(this.$globalVariable.header_wsTimer);
   this.$globalVariable.header_wsTimer = null;
   this.$globalVariable.header_ws = null;
   setTimeout(this.initWebsocket, 1000);
  },
  reload() {
   if (
    this.res.templates["headerTemplate"].url &&
    this.res.templates["headerTemplate"].url != ""
   ) {
    this.apiEndpoint = this.res.templates["headerTemplate"].url;
    this.getCmsList().then((res) => {
     this.res = { ...this.res, ...res };
     if (window.DEBUG) console.log("header_dataload");
     setTimeout(() => {
      this.jQuery("#header_dataload_click").click();
     }, 50);
     if (this.res.header_dataload) this.res.header_dataload(this);
    });
    if (
     this.res.templates["headerTemplate"].url.startsWith("ws://") ||
     this.res.templates["headerTemplate"].url.startsWith("wss://")
    ) {
     this.initWebsocket(this.res.templates["headerTemplate"].url);
    } else if (this.res.templates["headerTemplate"].url.startsWith("{")) {
     const endpoints = JSON.parse(this.res.templates["headerTemplate"].url);
     for (var k in endpoints) {
      if (
       endpoints[k].startsWith("ws://") ||
       endpoints[k].startsWith("wss://")
      ) {
       this.initWebsocket(endpoints[k]);
      }
     }
    }
   }
  },
 },
 mounted: function () {
  if (window.DEBUG) console.log("Header  mounted");
  const storageRes = this.getStorage("res");
  if (storageRes && typeof storageRes === "object") {
    // 合併storageRes
    Object.keys(storageRes).forEach((key) => {
      this.res[key] = storageRes[key];
    });
  }
  this.reload();

  if(window.DEBUG)console.log("header_load_click");
  if (this.template !== "" && this.template.indexOf("header_load_click") > -1) {
   const observer = new MutationObserver((mutationsList, observer) => {
    for (const mutation of mutationsList) {
     mutation.addedNodes.forEach((node) => {
      if (node.id === "header_load_click") {
       $("#header_load_click").click();
       observer.disconnect(); // 停止觀察
      }
     });
    }
   });
   observer.observe(document.body, { childList: true, subtree: true });
  }
 },
};
</script>