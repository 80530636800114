<template  ref="htmlContainer" class="route_center">
 <v-runtime-template :template="template"></v-runtime-template>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
/* eslint-disable vue/no-unused-components */
import VRuntimeTemplate from "vue3-runtime-template";
import OwlCarousel from "./OwlCarousel.vue";
import DataList from "./DataList.vue";
import DataForm from "./DataForm.vue";
import FCalendar from "./FCalendar.vue";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
/* eslint-disable vue/no-unused-components */
import Datepicker from "vue3-datepicker";

/* eslint-disable no-unused-vars */
import moment from "moment";
axios.defaults.withCredentials = true;
const api = axios.create({
 baseURL: window.API_URL || "/api/",
});
export default {
 name: "CmsPage",
 components: {
  VRuntimeTemplate,
  FCalendar,
  OwlCarousel,
  DataList,
  DataForm,
  Datepicker,
 },
 data() {
  return {
   template: "",
   apiEndpoint: "Cms/PageData?pagename=" + this.$route.name,
   res: {},
   loading: false,
   route: useRoute(),
   router: useRouter(),
   v: this,
   w: window,
   observer: null,
   entries: null,
   h: axios
  };
 },
 methods: {
  //新傳記用 sweetalert 開 form
  swalFire(obj, _then) {
   var v = this;
   if (obj.preConfirmFormID) {
    obj.preConfirm = this.preCheckForm(obj.preConfirmFormID);
   }
   Swal.fire(obj).then((result) => {
    if (obj.isConfirmed && result.isConfirmed) obj.isConfirmed(result);
    if (_then) _then(result, v, window);
   });
  },
  preCheckForm(formID) {
   return new Promise((resolve, reject) => {
    setTimeout(() => {
     const inputs = document.querySelectorAll(
      "#" + formID + " input,#" + formID + " textarea,#" + formID + " select"
     );
     for (let input of inputs) {
      const pattern = input.getAttribute("pattern");
      if (pattern && !new RegExp(pattern).test(input.value)) {
       reject("驗證失败");
       return;
      }
     }
     let result = {};
     inputs.forEach((input) => {
      result[input.name] = input.value;
     });
     resolve(result);
    }, 200);
   });
  },
  sumOfList(list, prop) {
   var s = 0;
   if (list && Array.isArray(list)) {
    list.forEach((element) => {
     s += parseFloat(element[prop]);
    });
   }
   return s;
  },
  sumOfObj(dyn, call) {
   if (!dyn) return 0;
   var s = 0;
   for (var i in dyn) {
    s += parseFloat(call(dyn[i]));
   }
   return s;
  },
  sha512encode(a) {
   var hash1 = window.sha512.create();
   hash1.update(a);
   return hash1.hex();
  },
  saveStorage(a, b) {
   this.$globalVariable[a] = b;
   localStorage.setItem(a, JSON.stringify(b));
  },
  getStorage(a) {
   if (!localStorage.getItem(a) && this.$globalVariable[a])
    return this.$globalVariable[a];
   var b = localStorage.getItem(a);
   if (b) b = JSON.parse(b);
   return b;
  },
  uploadFile(
   url,
   index,
   files,
   onUploadProgress,
   onUploadFileComplete,
   onUploadFileError,
   onAllUploaded
  ) {
   if (index < files.length) {
    const file = files[index];
    const formData = new FormData();
    formData.append("image", file);
    const token = this.getStorage("auth_token");
    axios
     .post(url, formData, {
      headers: {
       ...(token && { Authorization: `Bearer ${token}` }),
       "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
       const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
       );
       onUploadProgress(index, percentCompleted);
      },
     })
     .then((response) => {
      onUploadFileComplete(index, response);
      this.uploadFile(
       url,
       index + 1,
       files,
       onUploadProgress,
       onUploadFileComplete,
       onUploadFileError,
       onAllUploaded
      ); // Recurse to upload the next file
     })
     .catch((err) => {
      onUploadFileError(index, err);
     });
   } else {
    onAllUploaded();
   }
  },
  fetch(actionUrl, succ, err) {
   var v = this;
   const token = this.getStorage("auth_token");
   axios.defaults.withCredentials = true;
   axios
    .get(actionUrl, {
     withCredentials: true,
     headers: token ? { Authorization: `Bearer ${token}` } : {},
    })
    .then((response) => {
     if (window.DEBUG) console.log(response);
     if (response.data && response.data.error && err) {
      err(response.data.error, v, window);
     } else if (succ) {
      succ(response.data, v, window);
     }
    })
    .catch((error) => {
     if (window.DEBUG) console.error("Error fetching items:", error);
     if (err) {
      err(error, v, window);
     }
    });
  },
  request_stringify(data) {
   return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
  },
  post(actionUrl, data, succ, err) {
   var v = this;
   axios.defaults.withCredentials = true;
   const token = this.getStorage("auth_token");
   axios
    .post(actionUrl, data, {
     headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
     if (window.DEBUG) console.log(response);
     if (response.data && response.data.error && err) {
      err(response.data.error, v, window);
     } else if (succ) {
      succ(response.data, v, window);
     }
    })
    .catch((error) => {
     if (window.DEBUG) console.error("Error fetching items:", error);
     if (err) {
      err(error, v, window);
     }
    });
  },
  submitFormJson(formID, actionUrl, succ, err) {
   var v = this;
   var formData = {};
   $("#" + formID)
    .find(":input[name]")
    .each(function () {
     formData[this.name] = $(this).val();
    });
   axios.defaults.withCredentials = true;
   const token = this.getStorage("auth_token");
   axios
    .post(actionUrl, "json=" + JSON.stringify(formData), {
     headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
     if (window.DEBUG) console.log(response);
     if (response.data && response.data.error && err) {
      err(response.data.error, v, window);
     } else if (succ) {
      succ(response.data, v, window);
     }
    })
    .catch((error) => {
     if (window.DEBUG) console.error("Error fetching items:", error);
     err(error, v, window);
    });
  },
  createFormData(data) {
   const formData = new FormData();
   for (const key in data) {
    if (data[key]) {
     formData.append(key, data[key]);
    }
   }
   return formData;
  },
  jQuery(selectort) {
   return $(selectort);
  },
  validForm(formID) {
   var v = this;
   this.jQuery("#" + formID + " p.error").remove();
   this.jQuery("#" + formID + " .is-invalid").removeClass("is-invalid");
   var ei = this.jQuery(
    "#" +
     formID +
     " input[required],#" +
     formID +
     "  select[required],#" +
     formID +
     "  textarea[required]"
   ).filter(function () {
    return (
     v.jQuery(this).is(":visible") &&
     (!v.jQuery(this).val() || v.jQuery(this).val().trim() === "")
    );
   });
   if (ei.length > 0) {
    ei.addClass("is-invalid");
    ei.toArray().forEach((value) => {
     if (v.jQuery(value).attr("required-message"))
      v.jQuery(value).after(
       "<p class='error invalid-feedback'>" +
        v.jQuery(value).attr("required-message") +
        "</p>"
      );
    });
    this.jQuery(ei[0]).focus();
    return false;
   }
   ei = this.jQuery(
    "#" +
     formID +
     " input[pattern], #" +
     formID +
     " select[pattern], #" +
     formID +
     " textarea[pattern]"
   ).filter(function () {
    var pattern = v.jQuery(this).attr("pattern");
    var value = v.jQuery(this).val();
    var regex = new RegExp(pattern);
    if (window.DEBUG) console.log(pattern);
    if (window.DEBUG) console.log(value);
    if (window.DEBUG) console.log(regex.test(value));
    return v.jQuery(this).is(":visible") && !regex.test(value);
   });
   if (ei.length > 0) {
    ei.addClass("is-invalid").focus();
    ei.toArray().forEach((value) => {
     if (v.jQuery(value).attr("invalid-message"))
      v.jQuery(value).after(
       "<p class='error invalid-feedback'>" +
        v.jQuery(value).attr("invalid-message") +
        "</p>"
      );
    });
    return false;
   }
   return true;
  },
  //新傳記用 submitFormJson jioushin 改 submitForm 並加上 valid
  //新傳紀用 "json=" + JSON.stringify(formData)  回歸formData
  //三益 @click.prevent="submitForm('register_form', '/api/member/signup', null,'ok', 'err')
  // <button id="ok" @click.prevent="$route.push('success')" style="display:none">ok</button>
  // <button id="err" @click.prevent="swalFire({'icon':'error','html':res.lastState.error})" style="display:none">ok</button>
  submitForm(formID, actionUrl, confirm_string, succ, err) {
   if (window.DEBUG) console.log("postForm");
   var vm = this;
   if (this.loading) return;
   if (!this.validForm(formID)) return;
   if (confirm_string && !confirm(confirm_string)) return;
   var formData = {};
   $("#" + formID)
    .find(":input[name]")
    .each(function () {
     if (this.type == "password")
      formData[this.name] = vm.sha512encode($(this).val());
     else formData[this.name] = $(this).val();
    });
   axios.defaults.withCredentials = true;
   const token = this.getStorage("auth_token");
   axios
    .post(actionUrl, formData, {
     headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
     if (window.DEBUG) console.log(response);
     vm.res.lastState = response.data;
     if (response.data && response.data.error && err) {
      err(response.data.error, vm, window);
     } else if (succ) {
      succ(response.data, vm, window);
     } else this.reload();
    })
    .catch((error) => {
     vm.res.lastState = { error: error.message };
     if (window.DEBUG) console.error("Error fetching items:", error);
     if (err) {
      err(err, vm, window);
     } else this.reload();
    });
  },
  replaceApiEndpoint(endpoint, params, query) {
   const getValueByPath = (obj, path) => {
    return path
     .split(".")
     .reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
     );
   };

   return endpoint
    .replace(/(\$\{route.name\})/g, this.$route.name) // 替換 ${route.name}
    .replace(/(\$\{.+?\})/g, (match) => {
     // 去掉 ${ 和 }，提取內部內容
     const expression = match.slice(2, -1);

     // 檢查是否為 query_key=paramName 格式
     const [queryKey, paramName] = expression.split("=");

     if (queryKey && paramName) {
      // 如果 paramName 是嵌套路徑，動態獲取值
      const paramValue = paramName.startsWith("res.")
       ? getValueByPath(this.res, paramName.slice(4))
       : query[paramName];

      if (paramValue !== undefined) {
       return `${queryKey}=${encodeURIComponent(paramValue)}`;
      } else {
       // 如果對應值不存在，移除變數
       return "";
      }
     } else {
      // 普通變數替換
      const paramValue = expression.startsWith("res.")
       ? getValueByPath(this, expression)
       : params[expression] || query[expression];

      if (paramValue !== undefined) {
       return encodeURIComponent(paramValue);
      } else {
       // 如果對應值不存在，移除變數
       return "";
      }
     }
    });
  },
  isUrlEncoded(url) {
   let oldUrl = "";
   let newUrl = decodeURIComponent(url);

   while (oldUrl !== newUrl) {
    if (!/%[0-9a-f]{2}/i.test(newUrl)) {
     return false; // 沒有編碼字符模式，不是雙重編碼
    }
    oldUrl = newUrl;
    try {
     newUrl = decodeURIComponent(oldUrl);
    } catch (e) {
     // 解碼錯誤，可能由於已經達到最底層的未編碼字符串
     return true;
    }
   }

   return oldUrl !== url; // 如果初始和最終字符串不相同，則為雙重編碼
  },
  customEncodeURIComponent(path) {
   // 將路徑分割為段，然後對每段進行編碼，最後重新組合
   if (!path) return null;
   return path
    .split("/")
    .map((segment) =>
     this.isUrlEncoded(segment) ? segment : encodeURIComponent(segment)
    )
    .join("/");
  },
  customDecodeURIComponent(path) {
   // 將路徑分割為段，然後對每段進行編碼，最後重新組合
   if (!path) return null;
   return path
    .split("/")
    .map((segment) =>
     this.isUrlEncoded(segment) ? segment : decodeURIComponent(segment)
    )
    .join("/");
  },
  check_path(routes, apath) {
   var result = null;
   routes.forEach((route) => {
    if (route.path == apath) result = apath;
    else if (route.path == this.customDecodeURIComponent(apath))
     result = this.customDecodeURIComponent(apath);
    else if (route.path == this.customEncodeURIComponent(apath))
     result = this.customEncodeURIComponent(apath);
    // 如果存在子路由，遞迴處理
    else if (route.children && route.children.length > 0) {
     result = this.check_path(route.children, apath);
    }
   });
   return result;
  },
  setupLinkListeners() {
   const container = this.$refs.htmlContainer;
   if (container) {
    container.addEventListener("click", (e) => {
     if (
      e.target.tagName === "A" &&
      e.target.getAttribute("href") &&
      e.target.getAttribute("href")[0] == "/"
     ) {
      var path3 = this.check_path(
       this.$router.getRoutes(),
       e.target.getAttribute("href")
      );
      if (path3) {
       e.preventDefault();
       this.$router.push(path3);
      }
     }
    });
   }
  },
  refresh() {
   this.$router.go(this.$router.currentRoute);
  },
  query(a) {
   var queryString = window.location.search;
   var urlParams = new URLSearchParams(queryString);
   return urlParams.get(a);
  },
  initWebsocket(wsURL) {
   if (this.$globalVariable.ws && this.$globalVariable.ws !== null) return;
   if (!wsURL && this.$globalVariable.wsURL) wsURL = this.$globalVariable.wsURL;
   if (!wsURL) return;
   this.$globalVariable.wsURL = wsURL;
   this.$globalVariable.ws = new WebSocket(wsURL);
   this.$globalVariable.ws.onopen = this.websocketonopen;
   this.$globalVariable.ws.onerror = this.websocketonerror;
   this.$globalVariable.ws.onmessage = this.websocketonmessage;
   this.$globalVariable.ws.onclose = this.websocketclose;
   this.$globalVariable.ws_route = this.$route.name;
  },

  waitForConnection(callback, interval) {
   if (this.$globalVariable.ws && this.$globalVariable.ws.readyState === 1) {
    callback();
   } else {
    setTimeout(() => {
     this.waitForConnection(callback, interval);
    }, interval);
   }
  },

  websocketonopen() {
   this.websocketsend("view=" + this.$route.name);
   this.$globalVariable.wsTimer = setInterval(() => {
    if (this.$globalVariable.ws_route != this.$route.name)
     this.websocketclose();
    else this.websocketsend("1");
   }, 3000);
  },

  websocketonerror() {
   clearInterval(this.wsTimer);
   this.$globalVariable.wsTimer = null;
   this.$globalVariable.ws = null;
   if (this.$globalVariable.ws_route == this.$route.name)
    setTimeout(this.initWebsocket, 1000);
  },

  websocketonmessage(e) {
   const data = e.data;
   if (data === "OK" || data === "load" || data === "2") return;

   try {
    const jsonData = JSON.parse(data);
    if (!this.$globalVariable.ws_response)
     this.$globalVariable.ws_response = [];
    this.$globalVariable.ws_response.push(jsonData);
    $("#websocketonmessage").click();
    if (this.res.websocketonmessage) this.res.websocketonmessage(this);
   } catch (error) {
    if (window.DEBUG) console.error("Error parsing JSON:", error);
   }
  },

  websocketsend(data) {
    if (this.$globalVariable.ws && this.$globalVariable.ws.readyState === WebSocket.OPEN) {
    this.$globalVariable.ws.send(data);
   }
  },

  websocketclose() {
   clearInterval(this.$globalVariable.wsTimer);
   this.$globalVariable.wsTimer = null;
   this.$globalVariable.ws = null;
   if (this.$globalVariable.ws_route == this.$route.name)
    setTimeout(this.initWebsocket, 1000);
  },
  getCmsList() {
   if (
    this.apiEndpoint.startsWith("ws://") ||
    this.apiEndpoint.startsWith("wss://")
   ) {
    return Promise.resolve();
   }

   if (
    this.apiEndpoint.startsWith("http://") ||
    this.apiEndpoint.startsWith("https://")
   ) {
    axios.defaults.withCredentials = true;
    const token = this.getStorage("auth_token");
    return axios
     .get(
      this.replaceApiEndpoint(
       this.apiEndpoint,
       this.$route.params,
       this.$route.query
      ),
      {
       withCredentials: true,
       headers: token ? { Authorization: `Bearer ${token}` } : {},
      }
     )
     .then((response) => {
      return response.data;
     })
     .catch((error) => {
      if (window.DEBUG) console.error("请求外部 API 时出错:", error);
      if (error.response) return error.response.data;
      return Promise.reject(error);
     });
   } else if (this.apiEndpoint.startsWith("/")) {
    return api
     .get(
      this.replaceApiEndpoint(
       this.apiEndpoint,
       this.$route.params,
       this.$route.query
      )
     )
     .then((response) => response.data)
     .catch((error) => {
      if (window.DEBUG) console.error("Error fetching web config:", error);
      if (error.response) return error.response.data;
      return Promise.reject(error);
     });
   } else if (this.apiEndpoint.startsWith("{")) {
    try {
     const endpoints = JSON.parse(this.apiEndpoint);
     const promises = Object.entries(endpoints).map(([key, endpoint]) => {
      if (endpoint.startsWith("ws://") || endpoint.startsWith("wss://")) {
       return Promise.resolve();
      }
      if (endpoint.startsWith("http://") || endpoint.startsWith("https://")) {
       axios.defaults.withCredentials = true;
       const token = this.getStorage("auth_token");
       return axios
        .get(
         this.replaceApiEndpoint(
          endpoint,
          this.$route.params,
          this.$route.query
         ),
         {
          withCredentials: true,
          headers: token ? { Authorization: `Bearer ${token}` } : {},
         }
        )
        .then((response) => {
         return { [key]: response.data };
        })
        .catch((error) => {
         if (window.DEBUG) console.error("请求外部 API 时出错:", error);
         if (error.response) return { [key]: error.response.data };
         return Promise.reject(error);
        });
      }
      return api
       .get(
        this.replaceApiEndpoint(endpoint, this.$route.params, this.$route.query)
       )
       .then((response) => ({ [key]: response.data }));
     });

     return Promise.all(promises)
      .then((results) => {
       const combinedResults = Object.assign({}, ...results);
       return combinedResults;
      })
      .catch((error) => {
       if (window.DEBUG)
        console.error("Error fetching multiple web configs:", error);
        throw error;
      });
    } catch (error) {
     if (window.DEBUG) console.error("Error parsing JSON apiEndpoint:", error);
     throw error;
    }
   }
  },
  load_template() {
   // 查找或創建一個動態的 <style> 標籤
   let styleTag = document.getElementById("dynamic-style");
   styleTag.innerHTML = "";
   this.apiEndpoint = "";
   this.template = "";
   this.res = {};
   if (this.$globalVariable.ws) this.websocketclose();
   const storageRes = this.getStorage("res");
   if (storageRes && typeof storageRes === "object") {
    // 合併storageRes
    Object.keys(storageRes).forEach((key) => {
     this.res[key] = storageRes[key];
    });
   }
   var index = this.res.route.findIndex((x) => x.name == this.$route.name);

   var changed = false;
   if (
    index > -1 &&
    this.res.route[index].template &&
    this.res.route[index].template in this.res.templates
   ) {
    this.template = this.res.templates[this.res.route[index].template].content;
    changed = true;
    if (this.res.templates[this.res.route[index].template].css) {
     styleTag.innerHTML =
      this.res.templates[this.res.route[index].template].css;
    }
    if (
     this.res.templates[this.res.route[index].template].url &&
     this.res.templates[this.res.route[index].template].url != ""
    )
     this.apiEndpoint = this.res.templates[this.res.route[index].template].url;
   } else if (this.$route.name in this.res.templates) {
    this.template = this.res.templates[this.$route.name].content;
    changed = true;
    if (this.res.templates[this.$route.name].css) {
     styleTag.innerHTML = this.res.templates[this.$route.name].css;
    }
    if (
     this.res.templates[this.$route.name].url &&
     this.res.templates[this.$route.name].url != ""
    )
     this.apiEndpoint = this.res.templates[this.$route.name].url;
   }

   if (
    this.template !== "" &&
    changed &&
    this.template.indexOf("init_click") > -1
   ) {
    const observer = new MutationObserver((mutationsList, observer) => {
     for (const mutation of mutationsList) {
      mutation.addedNodes.forEach((node) => {
       if (node.id === "init_click") {
        $("#init_click").click();
        observer.disconnect(); // 停止觀察
       }
      });
     }
    });
    observer.observe(document.body, { childList: true, subtree: true });
   }
   if (this.template !== "" && changed) {
    this.$nextTick(() => {
     this.setupLinkListeners();
    });
   }
  },
  reload() {
   if (window.DEBUG) console.log("Page.vue mounted");
   if (this.apiEndpoint && this.apiEndpoint != "") {
    $("#app").addClass("loading");
    this.getCmsList().then((res) => {
     if (res) this.res = { ...this.res, ...res };
     if (window.DEBUG) console.log("dataload_click");
     setTimeout(() => {
      $("#app").removeClass("loading");
      $("#dataload_click").click();
     }, 50);
     if (this.res.dataload) this.res.dataload(this);

     if (
      this.apiEndpoint.startsWith("ws://") ||
      this.apiEndpoint.startsWith("wss://")
     ) {
      this.initWebsocket(this.apiEndpoint);
     } else if (this.apiEndpoint.startsWith("{")) {
      const endpoints = JSON.parse(this.apiEndpoint);
      for (var k in endpoints) {
       if (
        endpoints[k].startsWith("ws://") ||
        endpoints[k].startsWith("wss://")
       ) {
        this.initWebsocket(endpoints[k]);
       }
      }
     }
    });
   }

   // 代替 @load

   //  const observer = new MutationObserver((mutationsList, observer) => {
   //   for (let mutation of mutationsList) {
   //    // 检查是否有新的节点被添加
   //    mutation.addedNodes.forEach((node) => {
   //     // 判断是否为我们需要的元素
   //     if (node.id === "load_click" && !node.hasAttribute("data-clicked")) {
   //      node.click(); // 觸發點擊事件
   //      node.setAttribute("data-clicked", "true"); // 設置標記，防止重複點擊
   //      observer.disconnect(); // 操作完成後斷開觀察
   //     }
   //    });
   //   }
   //  });

   //  // 开始观察文档中的变化，观察整个文档树（可以根据需要调整为某个具体的父节点）
   //  observer.observe(document.body, {
   //   childList: true, // 监听子节点的变化
   //   subtree: true, // 观察整个子树中的节点
   //  });
  },
 },
 watch: {
  // Watch for route changes
  $route() {
   if (window.DEBUG) console.log("cms");
   if (window.DEBUG) console.log(this.route);
   try {
    this.load_template();
    this.reload();
    if (this.template !== "" && this.template.indexOf("load_click") > -1) {
     const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
       mutation.addedNodes.forEach((node) => {
        if (node.id === "load_click") {
         $("#load_click").click();
         observer.disconnect(); // 停止觀察
        }
       });
      }
     });
     observer.observe(document.body, { childList: true, subtree: true });
    }

    if (this.res.load) this.res.load(this);
   } catch (e) {
    console.log("load_template error" + e);
   }
  },
 },
 computed: {
  globalVar() {
   return this.$globalVariable;
  },
 },
 mounted: async function () {
  document.addEventListener("scroll", () => {
   $("#document_scroll").click();
   if (this.res.document_scroll) this.res.document_scroll(this);
  });
  if (window.DEBUG) console.log(this);
  try {
   this.load_template();
   this.reload();

   if (this.template !== "" && this.template.indexOf("load_click") > -1) {
    const observer = new MutationObserver((mutationsList, observer) => {
     for (const mutation of mutationsList) {
      mutation.addedNodes.forEach((node) => {
       if (node.id === "load_click") {
        $("#load_click").click();
        observer.disconnect(); // 停止觀察
       }
      });
     }
    });
    observer.observe(document.body, { childList: true, subtree: true });
   }

   if (this.res.load) this.res.load(this);
  } catch (e) {
   console.log("load_template error" + e);
  }
 },
 beforeUnmount: async function () {
  if (this.$globalVariable.ws) {
   this.websocketclose();
  }
 },
};

/*


   setTimeout(() => {
    $("html,body").animate({ scrollTop: 0 });
    $("#load_click_lazy").click(); // 代替 @load
   }, 200);
*/
</script>