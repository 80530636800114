<template>
 <v-runtime-template :template="template"></v-runtime-template>
</template>
<script>
import VRuntimeTemplate from "vue3-runtime-template";
import $ from "jquery";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
axios.defaults.withCredentials = true;
export default {
 name: "BottomView",
 data() {
  var res = JSON.parse(localStorage.getItem("res"));
  return {
   v: this,
   w: window,
   h: axios,
   template: "",
   template_name: "",
   res: res,
   route: useRoute(),
   router: useRouter(),
  };
 },
 components: {
  VRuntimeTemplate,
 },
 watch: {
  // Watch for route changes
  $route() {
   this.reload();
   if(window.DEBUG)console.log("bottomview_route_reload");
   $("#bottomview_route_reload").click();
  },
 },
 methods: {
  jQuery(selectort) {
   return $(selectort);
  },
  refresh() {
   this.$router.go(this.$router.currentRoute);
  },
  saveStorage(a, b) {
   this.$globalVariable[a] = b;
   localStorage.setItem(a, JSON.stringify(b));
  },
  getStorage(a) {
   if (!localStorage.getItem(a)) return this.$globalVariable[a];
   var b = localStorage.getItem(a);
   if (b) b = JSON.parse(b);
   return b;
  },
  reload() {
   var res = JSON.parse(localStorage.getItem("res"));
   var index = res.route.findIndex((x) => x.name == this.$route.name);
   if (
    index > -1 &&
    res.route[index].bottom &&
    res.route[index].bottom in res.templates
   ) {
    if (this.template_name != res.route[index].bottom) {
     this.template_name = res.route[index].bottom;
     this.template = res.templates[res.route[index].bottom].content;
     setTimeout(() => {
      if(window.DEBUG)console.log("bottomview_template_changed");
      $("#bottomview_template_changed").click();
     }, 250);
    }
   }
   else
   {
     this.template_name = "";
     this.template = "";
   }
  },
 },
 mounted: function () {
  this.reload();
  if(window.DEBUG)console.log("bottomview_load");
  $("#bottomview_load").click();
 },
};
</script>